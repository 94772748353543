<template>
    <div>
        <span v-if="element.serviceDetails && [1,2,3,4,8].includes(parseInt(element.extraServiceId))"
             class="label label-warning d-inline-block mr-2 pl-2 pr-2 mb-0">
            <span v-if="element.extraServiceId !== 8">{{element.serviceDetails}}</span>
            <el-tooltip  :content="issuesType[element.serviceDetails]" placement="bottom" effect="light" v-else>
                <span>{{issuesType[element.serviceDetails] | truncate(25)}}</span>
            </el-tooltip>
        </span>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    export default {
        props: {
            element: {
                type: Object,
                default() {
                    return null;
                },
            },
        },
        watch: {
            element() {
                if (this.element.serviceDetails) {
                    this.checkedPort = this.element.serviceDetails.split(',');
                }
            }
        },
        computed: {
            ...mapState({
                issuesType: (state) => Object.assign({
                    "1": "UNABLE TO CONNECT",
                    "2": "BLACK SCREEN",
                    "3": "CANT CONNECT Z DRIVE",
                    "4": "NO DATA FOUND ON Z DRIVE",
                }, state.auth.reportIssuesType.reduce((a, v) => ({ ...a, [v.value]: v.name }), {})),
            }),
        },
        data() {
            return {
                checkedPort: []
            };
        },
        created() {
            if (this.element.serviceDetails) {
                this.checkedPort = this.element.serviceDetails.split(',');
            }
        }
    }
</script>